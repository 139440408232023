<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      okText="授予"
      title="权限列表"
      width="60%"
      @cancel="handleCancel" @ok="handleOk"
  >
    <a-form layout="inline">
      <a-form-item label="权限编号">
        <a-input v-model="query.permissionCode" placeholder="请输入权限编号"/>
      </a-form-item>

      <a-form-item label="权限名称">
        <a-input v-model="query.permissionName" placeholder="请输入权限名称"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             :row-selection="permissionRowSelection"
             style="margin-top: 24px;" @change="handleTableChange">
    </a-table>
  </a-modal>
</template>

<script>
import {queryPermissionList} from "@/api/route";
import _ from "lodash";

export default {
  name: "permissionSelect",
  data() {
    return {
      query: {
        permissionName: undefined,
        permissionCode: undefined,
      },
      columns: [
        {
          title: '权限编号',
          dataIndex: 'permissionCode',
          key: 'permissionCode',
        },
        {
          title: '权限名称',
          dataIndex: 'permissionName',
          key: 'permissionName',
        },
        {
          title: '权限',
          dataIndex: 'permission',
          key: 'permission',
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      visible: false,
      selectedPermissionCode: []
    }
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
      this.queryPermissionList()
    },
    handleOk(e) {
      this.$emit('success', this.selectedPermissionCode)
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryPermissionList()
    },
    async queryPermissionList() {
      this.loading = true

      let data = await queryPermissionList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryPermissionList()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (permission, index) => {
        return {
          key: permission.id,
          ...permission,
        }
      })
    },
  },
  computed: {
    permissionRowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedPermissionCode = _.map(selectedRows, row => {
            return row.permissionCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
