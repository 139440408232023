<template>
  <a-modal
      v-if="visible"
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" type="inline">
      <a-form-item label="角色名称">
        <a-input
            v-decorator="['roleName', { rules: [{ required: true, message: '请输入角色名称' }], initialValue: item && item.roleName }]"
        />
      </a-form-item>
      <a-form-item label="角色描述">
        <a-input
            v-decorator="['represent', { rules: [{ required: true, message: '请输入路由名称' }], initialValue: item && item.represent }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import {deleteUserCache, updateRole} from "@/api/route";

export default {
  name: "roleEdit",
  props: {
    item: {}
  },
  mounted() {
  },
  data() {
    return {
      title: this.item ? '修改角色' : '新增角色',
      visible: false,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    handleOk(e) {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await updateRole({
            ...this.item,
            ...values,
          })

          await deleteUserCache()
          this.$message.success('成功')
          this.visible = false;
          this.$emit('success')
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.title = this.item ? '修改角色' : '新增角色'
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
