<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      okText="授予"
      title="路由列表"
      width="70%"
      @cancel="handleCancel" @ok="handleOk">
    <a-form layout="inline">
      <a-form-item
          label="路由path"
      >
        <a-input v-model="query.path" placeholder="请输入路由path"/>
      </a-form-item>

      <a-form-item
          label="路由name"
      >
        <a-input v-model="query.name" placeholder="请输入路由name"/>
      </a-form-item>

      <a-form-item
          label="路由名称"
      >
        <a-input v-model="query.routeName" placeholder="请输入路由名称"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             :row-selection="rowSelection"
             style="margin-top: 24px;"
             @change="handleTableChange" @expand="handleExpand">
      <span slot="isPage" slot-scope="text, record">
        {{ text ? '是' : '否' }}
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import _ from "lodash";
import {deleteRoute, queryChildRoute, queryRouteList} from "@/api/route";

export default {
  name: "routeList",
  data() {
    return {
      query: {
        path: undefined,
        name: undefined,
        routeName: undefined,
        parentCode: undefined,
      },
      columns: [
        {
          title: '路由编号',
          dataIndex: 'routeCode',
          key: 'routeCode',
        },
        {
          title: '路由path',
          dataIndex: 'path',
          key: 'path',
        },
        {
          title: '路由name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '路由名称',
          dataIndex: 'routeName',
          key: 'routeName',
        },
        {
          title: '描述',
          dataIndex: 'represent',
          key: 'represent',
        },
        {
          title: '上级路由',
          dataIndex: 'parentCode',
          key: 'parentCode',
        },
        // {
        //   title: '是否为页面',
        //   dataIndex: 'isPage',
        //   key: 'isPage',
        //   scopedSlots: {customRender: 'isPage'},
        // },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      visible: false,
      selectedCode: []
    }
  },
  methods: {
    async queryRouteList() {
      this.loading = true

      let data = await queryRouteList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryRouteList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryRouteList()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (route, index) => {
        return {
          key: route.routeCode,
          ...route,
          children: []
        }
      })
    },
    async handleExpand(expanded, record) {
      if (expanded) {
        record.children = _.map((await queryChildRoute({parentCode: record.routeCode})).result, value => {
          return {
            key: value.routeCode,
            ...value,
            children: []
          }
        })
      }
    },
    toggleVisible() {
      this.visible = !this.visible
      this.queryRouteList()
    },
    handleOk(e) {
      this.$emit('success', this.selectedCode)
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedCode = _.map(selectedRows, row => {
            return row.routeCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
