<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      title="权限列表"
      width="60%"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-space>
      <a-button type="primary" @click="showRolePermissionSelect">
        授予权限
      </a-button>
      <a-popconfirm
          cancel-text="取消"
          ok-text="取消权限"
          title="确认取消权限？"
          @confirm="cancelRolePermission"
      >
        <a-button type="danger">
          取消权限
        </a-button>
      </a-popconfirm>
    </a-space>

    <a-table :columns="hasPermissionColumns" :data-source="hasPermissionData" :loading="hasPermissionLoading"
             :pagination="hasPermissionPagination" :row-selection="hasPermissionRowSelection">

    </a-table>

    <role-permission-select ref="rolePermissionSelect" @success="grantRolePermission"/>
  </a-modal>
</template>

<script>
import {deleteUserCache, grantRolePermission, queryPermissionList, queryRolePermission} from "@/api/route";
import _ from "lodash";
import rolePermissionSelect from "./rolePermissionSelect";

export default {
  name: "rolePermissionList",
  components: {rolePermissionSelect},
  data() {
    return {
      roleCode: undefined,
      visible: false,
      hasPermissionColumns: [
        {
          title: '权限名称',
          dataIndex: 'permissionName',
          key: 'permissionName',
        },
        {
          title: '权限',
          dataIndex: 'permission',
          key: 'permission',
        },
        {
          title: '字典',
          dataIndex: 'dictionary',
          key: 'dictionary',
        },
      ],
      hasPermissionData: [],
      hasPermissionPagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      hasPermissionLoading: true,
      selectedHasPermissionCode: []
    }
  },
  methods: {
    toggleVisible(roleCode) {
      this.roleCode = roleCode
      this.visible = !this.visible
      this.queryRolePermissionList()
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    async queryRolePermissionList() {
      this.hasPermissionLoading = true

      let data = await queryRolePermission({
        roleCode: this.roleCode
      })

      this.hasPermissionData = this.convertData(data.result.permissions)

      this.hasPermissionLoading = false
    },
    convertData(data) {
      return _.map(data, (permission, index) => {
        return {
          key: permission.permissionCode,
          ...permission,
        }
      })
    },
    async cancelRolePermission() {
      await grantRolePermission({
        roleCode: this.roleCode,
        isGrant: false,
        permissionCode: this.selectedHasPermissionCode,
      })
      await this.queryRolePermissionList()
      await deleteUserCache()
    },
    showRolePermissionSelect() {
      this.$refs.rolePermissionSelect.toggleVisible()
    },
    async grantRolePermission(permissionCode) {
      await grantRolePermission({
        roleCode: this.roleCode,
        isGrant: true,
        permissionCode: permissionCode,
      })
      await this.queryRolePermissionList()
      await deleteUserCache()
    }
  },
  computed: {
    hasPermissionRowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedHasPermissionCode = _.map(selectedRows, row => {
            return row.permissionCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
