<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      title="路由列表"
      width="60%"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-space>
      <a-button type="primary" @click="showRoleRouteSelect">
        授予路由
      </a-button>
      <a-popconfirm
          cancel-text="取消"
          ok-text="取消路由"
          title="确认取消路由？"
          @confirm="cancelRoleRoute"
      >
        <a-button type="danger">
          取消路由
        </a-button>
      </a-popconfirm>
    </a-space>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination" :row-selection="rowSelection">

    </a-table>

    <role-route-select ref="roleRouteSelect" @success="grantRoleRoute"/>
  </a-modal>
</template>

<script>
import {deleteUserCache, grantRoleRoute, queryRolePermission, queryRouteList} from "@/api/route";
import _ from "lodash";
import roleRouteSelect from "./roleRouteSelect";

export default {
  name: "roleRouteList",
  components: {roleRouteSelect},
  data() {
    return {
      roleCode: undefined,
      visible: false,
      columns: [
        {
          title: '路由',
          dataIndex: 'routeName',
          key: 'routeName',
        },
        {
          title: 'path',
          dataIndex: 'path',
          key: 'path',
        },
        {
          title: 'name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '前端值',
          dataIndex: 'fontValue',
          key: 'fontValue',
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        // current: 1,
        total: 0
      },
      loading: true,
      selectedCode: []
    }
  },
  methods: {
    toggleVisible(roleCode) {
      this.roleCode = roleCode
      this.visible = !this.visible
      this.queryRoleRouteList()
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    async queryRoleRouteList() {
      this.loading = true

      let data = await queryRolePermission({
        roleCode: this.roleCode
      })

      this.data = this.convertData(data.result.routePermissions)

      this.loading = false
    },
    convertData(data) {
      return _.map(data, (route, index) => {
        return {
          key: route.routeCode,
          ...route,
        }
      })
    },
    async cancelRoleRoute() {
      await grantRoleRoute({
        roleCode: this.roleCode,
        isGrant: false,
        routeCodes: this.selectedCode,
      })
      await this.queryRoleRouteList()
      await deleteUserCache()
    },
    showRoleRouteSelect() {
      this.$refs.roleRouteSelect.toggleVisible()
    },
    async grantRoleRoute(routeCode) {
      await grantRoleRoute({
        roleCode: this.roleCode,
        isGrant: true,
        routeCodes: routeCode,
      })
      await this.queryRoleRouteList()
      await deleteUserCache()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedCode = _.map(selectedRows, row => {
            return row.routeCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
