<template>
  <div>
    <a-form layout="inline">
      <a-form-item label="角色名称">
        <a-input v-model="query.roleName" placeholder="请输入角色名称"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="editRole(undefined)">增加</a-button>
      </a-form-item>
    </a-form>

    <a-table bordered :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             style="margin-top: 24px;"
             @change="handleTableChange">
      <span slot="action" slot-scope="text, record">
        <a @click="editRole(record)">编辑</a>
        <a-divider type="vertical"/>
        <a-popconfirm
            cancel-text="取消"
            ok-text="删除"
            title="确认删除？"
            @confirm="deleteRole(record)"
        >
          <a style="color: red;">删除</a>
        </a-popconfirm>
      </span>

      <span slot="permissionAction" slot-scope="text, record">
        <a @click="showPermissionList(record)">权限列表</a>
        <a-divider type="vertical"/>
        <a @click="showRoutePermissionList(record)">路由权限列表</a>
      </span>
    </a-table>

    <role-edit ref="edit" v-bind:item="currentRole" @success="queryRoleList"/>
    <role-permission-list ref="permissionList"/>
    <role-route-list ref="routeList"/>
  </div>
</template>

<script>
import roleEdit from './component/roleEdit'
import _ from "lodash";
import {deleteRole, deleteUserCache, queryRoleList} from "@/api/route";
import rolePermissionList from "./component/rolePermissionList";
import roleRouteList from "./component/roleRouteList";

export default {
  name: "roleList",
  components: {roleEdit, rolePermissionList, roleRouteList},
  data() {
    return {
      query: {
        roleName: undefined,
      },
      columns: [
        {
          title: '角色编号',
          dataIndex: 'roleCode',
          key: 'roleCode',
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
        },
        {
          title: '描述',
          dataIndex: 'represent',
          key: 'represent',
        },
        {
          title: '权限操作',
          key: 'permissionAction',
          scopedSlots: {customRender: 'permissionAction'},
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        }
      ],
      data: [],
      currentRole: undefined,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      emptyRole: {
        id: undefined,
        roleCode: undefined,
        represent: undefined,
        roleName: undefined,
      }
    }
  },
  mounted() {
    this.queryRoleList()
  },
  methods: {
    editRole(role) {
      this.currentRole = role
      this.$refs.edit.toggleVisible()
    },
    async queryRoleList() {
      this.loading = true

      let data = await queryRoleList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryRoleList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryRoleList()
    },
    async deleteRole(role) {
      await deleteRole([role.id])

      this.$message.success('删除成功')
      await this.queryRoleList()
      await deleteUserCache()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (role, index) => {
        return {
          key: role.roleCode,
          ...role,
        }
      })
    },
    showPermissionList(record) {
      this.$refs.permissionList.toggleVisible(record.roleCode)
    },
    showRoutePermissionList(record) {
      this.$refs.routeList.toggleVisible(record.roleCode)
    },
  },
}
</script>

<style scoped>

</style>
